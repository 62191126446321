// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-else-return */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

console.log('API Key:', process.env.REACT_APP_API_KEY);
console.log('VAPID Key:', process.env.REACT_APP_VAPID_KEY);

// Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyCfsPOS4REtmcmd8leWQ9L9DBUkdg2xi2A',
	authDomain: 'massagekey-uat.firebaseapp.com',
	projectId: 'massagekey-uat',
	storageBucket: 'massagekey-uat.appspot.com',
	messagingSenderId: '535482485671',
	appId: '1:535482485671:web:9ddff7a1ed94a828934482',
	measurementId: 'G-655KFFTYMV',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
	// eslint-disable-next-line consistent-return
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			// Get FCM token
			return getToken(messaging, {
				vapidKey:
					'BHLnGkessBvNmkNdKOAcow_eG8NnA5UVtCQ_DOOyAb9Nzki96Sfq6WLL4F808a5kpBKhzLemn-F4k8jyvR2N4d8',
			})
				.then((currentToken) => {
					if (currentToken) {
						localStorage.setItem('fcmToken', currentToken);
					} else {
						console.log('Failed to generate the app registration token.');
					}
				})
				.catch((err) => {
					console.log('An error occurred when requesting to receive the token.', err);
				});
		} else {
			console.log('User did not grant notification permission.');
		}
	});
};

// Call requestPermission when the app loads
requestPermission();

export const onMessageListener = (callback: any) => {
	const unsubscribe = onMessage(messaging, (payload: any) => {
		callback(payload);
	});

	return unsubscribe;
};
